<template>
  <div class="layoutDefault" style="margin-top: 2rem">
    <div class="grid grid-cols-1 gap-4">
      <div class="z-10">
        <div
          class="flex cursor-pointer rounded-lg py-2"
          style="max-width: 200px"
          @click="$router.go(-1)"
        >
          <IconArrow
            class="mr-3 mt-1 stroke-current text-habu h-6 w-6 stroke-2 transform rotate-0"
          />
          <span class="text-2xl text-normal">My Ticket</span>
        </div>
      </div>

      <div class="z-10">
        <div class="grid gap-2 sm:flex sm:space-x-3">
          <div
            class="card-ticket-form sm:rounded-t-11px cursor-pointer hover:bg-hgray6 relative"
            @click="setMaster('ticket_type', 0)"
          >
            <div class="text-xl font-bold text-htext pt-3.5 pb-3.5 sm:pb-0 text-center sm:px-5">
              All <br class="hidden sm:block" />Type
            </div>
            <p
              v-if="$route.query.ticket_type == 0"
              class="h-0 w-full rounded absolute left-0 bottom-0 sm:-bottom-2 transition-opacity opacity-100"
              style="border: 4px solid #ff0701"
            ></p>
          </div>

          <div class="card-ticket-form sm:rounded-t-11px relative flex-auto">
            <div class="right-3 top-2.5 absolute">
              <IconGuide class="cursor-pointer" />
            </div>

            <div class="relative">
              <p class="font-bold mt-2 text-22px text-center text-htext">
                {{ ticketTypes.technical.length ? ticketTypes.technical[0].group : '' }}
              </p>
            </div>
            <div class="flex justify-between overflow-x-auto sm:overflow-visible">
              <div
                class="group relative mt-2 py-2 px-5 sm:px-8 z-10 cursor-pointer hover:bg-hgray6 w-fit"
                v-for="m in ticketTypes.technical"
                :key="m.id"
                @click="setMaster('ticket_type', m.id)"
              >
                <div class="flex space-x-3">
                  <IconRequest
                    v-if="m.id == 2"
                    :class="$route.params.id == m.id ? 'mt-2.5' : 'mt-1.4'"
                  />
                  <IconTechnical
                    v-if="m.id == 1"
                    :class="$route.params.id == m.id ? 'mt-2.5' : 'mt-1.4'"
                  />

                  <p
                    class="mt-2 mb-1 text-black"
                    :class="$route.params.id == m.id ? 'font-bold text-2xl' : 'font-semibold'"
                  >
                    {{ m.name }}
                  </p>
                </div>

                <p
                  v-if="$route.query.ticket_type == m.id"
                  class="h-0 w-full rounded absolute left-0 bottom-0 sm:-bottom-2 transition-opacity opacity-100"
                  style="border: 4px solid #ff0701"
                ></p>
              </div>
            </div>
          </div>

          <div class="card-ticket-form sm:rounded-t-11px relative flex-auto">
            <div class="right-3 top-2.5 absolute">
              <IconGuide class="cursor-pointer" />
            </div>

            <div class="relative">
              <p class="font-bold mt-2 text-22px text-center text-htext">
                {{ ticketTypes.nontechnical.length ? ticketTypes.nontechnical[0].group : '' }}
              </p>
            </div>
            <div class="flex justify-between overflow-x-auto sm:overflow-visible">
              <div
                class="group relative mt-2 py-2 px-5 sm:px-8 z-10 cursor-pointer hover:bg-hgray6 w-fit"
                v-for="m in ticketTypes.nontechnical"
                :key="m.id"
                @click="setMaster('ticket_type', m.id)"
              >
                <div class="flex space-x-3">
                  <IconNTRequest
                    v-if="m.id == 4"
                    :class="$route.params.id == m.id ? 'mt-2.5' : 'mt-1.4'"
                  />
                  <IconNTComplaint
                    v-if="m.id == 3"
                    :class="$route.params.id == m.id ? 'mt-2.5' : 'mt-1.4'"
                  />

                  <p
                    class="mt-2 mb-1 text-black"
                    :class="$route.params.id == m.id ? 'font-bold text-2xl' : 'font-semibold'"
                  >
                    {{ m.name }}
                  </p>
                </div>

                <p
                  v-if="$route.query.ticket_type == m.id"
                  class="h-0 w-full rounded absolute left-0 bottom-0 sm:-bottom-2 transition-opacity opacity-100"
                  style="border: 4px solid #ff0701"
                ></p>
              </div>
            </div>
          </div>

          <!-- <div class="h-0 w-full relative" style="border: 1px solid #adadad"></div> -->
        </div>

        <div class="card-ticket-form-content mt-2 p-4 sm:p-8">
          <div class="grid grid-cols-1 sm:grid-cols-3 gap-3">
            <div class="sm:col-span-2 sm:flex sm:space-x-2 grid gap-3">
              <div class="form-filter relative" v-if="$route.query.ticket_type < 3">
                <treeselect
                  @select="filterSelect"
                  ref="ticket-list-instrument"
                  :multiple="false"
                  class="h-9"
                  v-model="instrument"
                  :options="listInstrument"
                  placeholder="Instrument"
                  instanceId="instrument_id"
                  :clearable="false"
                >
                  <label
                    slot="option-label"
                    slot-scope="{ node, labelClassName }"
                    :class="labelClassName"
                  >
                    <div>{{ node.label }}</div>
                    <div class="font-bold" style="font-size: 10px; color: #3c4d69d1">
                      {{ node.raw.type }}
                    </div>
                  </label>
                </treeselect>
                <div
                  class="absolute right-9 inset-y-0 flex items-center cursor-pointer"
                  v-if="instrument"
                  @click="filterClear('instrument', null)"
                >
                  <IconClear class="h-3 w-3" />
                </div>
              </div>
              <div class="form-filter relative">
                <treeselect
                  @select="filterSelect"
                  ref="ticket-list-status"
                  :multiple="false"
                  class="h-9"
                  v-model="status"
                  :options="statuses"
                  placeholder="Status"
                  instanceId="status"
                  :clearable="false"
                />
                <div
                  class="absolute right-9 inset-y-0 flex items-center cursor-pointer"
                  v-if="status"
                  @click="filterClear('status', null)"
                >
                  <IconClear class="h-3 w-3" />
                </div>
              </div>
              <div class="form-filter relative">
                <treeselect
                  @select="filterSelect"
                  ref="ticket-list-priority"
                  :multiple="false"
                  class="h-9"
                  v-model="priority"
                  :options="priorities"
                  placeholder="Priority"
                  instanceId="priority"
                  :clearable="false"
                />
                <div
                  class="absolute right-9 inset-y-0 flex items-center cursor-pointer"
                  v-if="priority"
                  @click="filterClear('priority', null)"
                >
                  <IconClear class="h-3 w-3" />
                </div>
              </div>
            </div>
            <div class="flex justify-end">
              <div class="form-filter relative w-full sm:w-auto">
                <input
                  type="text"
                  class="input w-full sm:w-auto"
                  placeholder="Type something here..."
                  style="padding-right: 36px"
                  v-model="filter"
                />
                <div class="absolute right-0 inset-y-0 flex items-center">
                  <SvgSearch class="mr-2 mb-1.5" />
                </div>
              </div>
            </div>
          </div>

          <div
            class="grid sm:flex sm:space-x-5 space-y-2 sm:space-y-0 mt-4"
            v-if="status == 'closed'"
          >
            <div class="form-filter relative">
              <v-date-picker
                v-model="dateRange"
                is-range
                :model-config="modelConfig"
                :input-debounce="500"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="sm:flex sm:space-x-2 space-y-2 sm:space-y-0">
                    <div class="relative">
                      <input
                        :value="inputValue.start"
                        v-on="inputEvents.start"
                        class="input h-9 xs:w-full"
                        placeholder="Start date"
                      />
                      <div
                        class="absolute right-0 inset-y-0 flex items-center cursor-pointer"
                        v-if="inputValue.start"
                        @click="clearRange"
                      >
                        <IconClear class="mr-3.5 h-3 w-3" />
                      </div>
                    </div>

                    <div class="relative">
                      <input
                        :value="inputValue.end"
                        v-on="inputEvents.end"
                        class="input h-9 xs:w-full"
                        placeholder="End date"
                      />
                      <div
                        class="absolute right-0 inset-y-0 flex items-center cursor-pointer"
                        v-if="inputValue.end"
                        @click="clearRange"
                      >
                        <IconClear class="mr-3.5 h-3 w-3" />
                      </div>
                    </div>
                  </div>
                </template>
              </v-date-picker>
            </div>
            <div class="form-filter relative">
              <button
                class="btn bg-hlow text-htextlow capitalize h-10 rounded-full w-28 hover:bg-hlow border-0"
                :class="exportLoading == true ? 'loading' : ''"
                :disabled="exportLoading"
                @click="toExport"
              >
                Export
              </button>
            </div>
          </div>

          <div class="grid sm:grid-cols-3 gap-4 mt-7" v-if="reqLoading">
            <div class="ticket-card relative animate-pulse" v-for="t in 9" :key="t">
              <div class="flex">
                <div class="mr-3">
                  <div class="h-9 bg-hline rounded-lg w-9"></div>
                </div>

                <div>
                  <div class="h-5 mb-1 bg-hline rounded-lg w-38"></div>

                  <div class="h-3 bg-hline rounded-lg w-32"></div>
                </div>
              </div>
              <div class="h-6 mt-2 bg-hline rounded-lg"></div>

              <div class="flex mt-2">
                <div class="mr-2">
                  <div class="h-4 w-4 bg-hline rounded-lg"></div>
                </div>

                <div class="flex-auto w-full">
                  <div class="h-4 bg-hline rounded-lg"></div>
                </div>
              </div>
              <div class="flex mt-1">
                <div class="mr-2">
                  <div class="h-4 w-4 bg-hline rounded-lg"></div>
                </div>

                <div class="flex-auto w-full">
                  <div class="h-4 bg-hline rounded-lg"></div>
                </div>
              </div>
              <div class="flex mt-1 mb-1">
                <div class="mr-2">
                  <div class="h-4 w-4 bg-hline rounded-lg"></div>
                </div>

                <div class="flex-auto w-full">
                  <div class="h-4 bg-hline rounded-lg"></div>
                </div>
              </div>

              <div class="flex">
                <div class="mr-2">
                  <div class="h-4 w-4 bg-hline rounded-lg"></div>
                </div>

                <div class="flex-auto w-full">
                  <div class="h-4 bg-hline rounded-lg"></div>
                </div>
              </div>
              <div class="flex space-x-2 mt-3">
                <div class="h-6 bg-hline rounded-lg w-14"></div>
                <div class="h-6 bg-hline rounded-lg w-14"></div>
              </div>

              <div class="absolute bottom-4 right-4 text-right">
                <div class="text-xs font-bold leading-1 uppercase">
                  <div class="h-3 bg-hline rounded-lg w-8"></div>
                </div>
                <div class="text-10px font-medium mt-0.5">
                  <div class="h-3 bg-hline rounded-lg w-8"></div>
                </div>
              </div>
            </div>
          </div>

          <div class="grid sm:grid-cols-3 gap-4 mt-7" v-else>
            <div class="my-36 mx-auto col-span-3" v-if="!tickets.length">
              <img :src="NoTickets" class="mx-auto" />
              <div class="font-semibold text-xl text-hblue text-center">
                There is no ticket yet!
              </div>
            </div>
            <div
              class="ticket-card relative cursor-pointer flex flex-col hover:bg-hgray6"
              v-for="t in tickets"
              :key="t.id"
              @click="toDetail(t)"
            >
              <div class="flex-grow">
                <div class="flex">
                  <div class="mr-3 pt-1.5" v-if="t.ticketType && t.ticketType.data">
                    <IconNTRequest v-if="t.ticketType.data.id == 4" />
                    <img v-else-if="t.ticketType.data.id == 2" :src="TicketRequest" />
                    <img v-else-if="t.ticketType.data.id == 3" :src="TicketNonTechnical" />
                    <img v-else :src="TicketTechnical" />
                  </div>

                  <div v-if="t.ticketType && t.ticketType.data">
                    <p class="text-black font-semibold leading-1 mt-2">
                      {{ t.ticketType.data.alias }}
                    </p>
                    <!-- <p class="text-hwarning2 text-sm leading-none">{{ t.ticketType.data.name }}</p> -->
                  </div>
                </div>
                <p class="text-sm font-semibold mt-2">{{ t.number }}</p>

                <div class="flex mt-2">
                  <div class="mr-2 pt-0.5">
                    <SvgPic class="w-3.5 h-3.5" />
                  </div>
                  <p class="text-sm font-semibold">{{ t.assigneeName ? t.assigneeName : '-' }}</p>
                </div>
                <div class="flex mt-1">
                  <div class="mr-2 pt-0.5">
                    <SvgDivision class="w-3.5 h-3.5" />
                  </div>
                  <p
                    class="text-sm font-medium"
                    v-if="t.ticketType && tt.includes(t.ticketType.data.id)"
                  >
                    {{ t.division ? t.division.data.name : '-' }}
                  </p>
                  <p class="text-sm font-medium" v-else>
                    {{ t.department ? t.department.data.name : '-' }}
                  </p>
                </div>
                <div class="flex mt-2" v-if="t.request">
                  <div class="mr-2 pt-0.5">
                    <SvgAction class="w-3.5 h-3.5" />
                  </div>
                  <p class="text-sm font-semibold">{{ t.request }}</p>
                </div>
                <div
                  class="flex mt-1 mb-1"
                  v-if="t.ticketType && tt.includes(t.ticketType.data.id)"
                >
                  <div class="mr-2 pt-0.5">
                    <SvgInstrumentXs class="w-3.5 h-3.5" />
                  </div>
                  <p class="text-sm font-medium">
                    {{
                      t.instrument
                        ? t.instrument.data.type + ' - ' + t.instrument.data.serial_number
                        : '-'
                    }}
                  </p>
                </div>

                <div class="flex">
                  <div class="mr-2 pt-0.5">
                    <SvgHuman class="w-3.5 h-3.5" />
                  </div>
                  <p class="text-sm font-medium">{{ t.staff_name ? t.staff_name : '-' }}</p>
                </div>
              </div>

              <div class="flex space-x-1 mt-3">
                <div
                  class="py-0.5 px-2 text-center text-xs font-semibold rounded-5px capitalize"
                  :class="{
                    'bg-hlow text-htextlow': t.priority.toLowerCase() == 'low',
                    'bg-hmedium text-htextmedium': t.priority.toLowerCase() == 'medium',
                    'bg-hhigh text-htexthigh': t.priority.toLowerCase() == 'high'
                  }"
                >
                  {{ t.priority }}
                </div>
                <div
                  class="py-0.5 px-3 capitalize text-center text-xs font-semibold rounded-5px"
                  :class="{
                    'bg-statusnew text-statustextnew': t.status == 'new',
                    'bg-statusconfirmed text-statustextconfirmed': t.status == 'confirmed',
                    'bg-statusstarted text-statustextstarted': t.status == 'started',
                    'bg-statusrestarted text-statustextrestarted': t.status == 'restarted',
                    'bg-statusonhold text-statustextonhold': t.status == 'held',
                    'bg-statusdone text-statustextdone': t.status == 'done',
                    'bg-statusclosed text-statustextclosed': t.status == 'closed'
                  }"
                >
                  {{ t.status }}
                </div>
              </div>

              <div class="absolute bottom-3.5 right-4 text-right">
                <div class="text-xs font-bold leading-1 uppercase">
                  {{ t.created_at ? t.created_at.date : null | moment('h:mm a') }}
                </div>
                <div class="text-10px font-medium">
                  {{ t.created_at ? t.created_at.date : null | moment('D MMM YYYY') }}
                </div>
              </div>
            </div>
          </div>

          <div
            class="flex justify-between space-x-2 mx-auto mt-8 text-center"
            style="max-width: 100px"
          >
            <button
              class="btn font-semibold bg-hteal1 hover:bg-hteal1 rounded-full btn-sm border-0"
              :disabled="currentPage == 1"
              @click="pageDecrease"
            >
              «
            </button>
            <button
              class="btn font-semibold rounded-full btn-sm btn-ghost no-animation hover:border-transparent"
            >
              {{ currentPage }}
            </button>
            <button
              class="btn font-semibold bg-hteal1 hover:bg-hteal1 rounded-full btn-sm border-0"
              :disabled="tickets.length <= 8"
              @click="pageIncrease"
            >
              »
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconClear from '@/assets/icons/icon-clear.svg?inline'
import IconArrow from '@/assets/icons/icon-arrow.svg?inline'
import IconGuide from '@/assets/icons/icon-guide.svg?inline'

import SvgSearch from '@/assets/icons/icon-search.svg?inline'
import SvgDivision from '@/assets/icons/icon-division.svg?inline'
import SvgInstrumentXs from '@/assets/icons/icon-instrument.svg?inline'
import SvgHuman from '@/assets/icons/icon-profile.svg?inline'
import SvgPic from '@/assets/icons/icon-picTicket.svg?inline'
import SvgAction from '@/assets/icons/icon-action.svg?inline'

import TicketTechnical from '@/assets/images/ticket-technical.png'
import TicketRequest from '@/assets/images/ticket-request.png'
import TicketNonTechnical from '@/assets/images/ticket-nontechnical.png'
import NoTickets from '@/assets/images/noTicket.png'

import IconTechnical from '@/assets/icons/icon-technical.svg?inline'
import IconRequest from '@/assets/icons/icon-nRequest.svg?inline'
import IconNTComplaint from '@/assets/icons/icon-nComplaint.svg?inline'
import IconNTRequest from '@/assets/icons/icon-ntRequest.svg?inline'

import Treeselect from '@riophae/vue-treeselect'

import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  name: 'ticket-index',
  components: {
    Treeselect,
    SvgSearch,
    SvgDivision,
    SvgInstrumentXs,
    SvgHuman,
    SvgPic,
    IconArrow,
    SvgAction,
    IconClear,
    IconTechnical,
    IconRequest,
    IconNTComplaint,
    IconNTRequest,
    IconGuide
  },
  data() {
    return {
      TicketTechnical,
      TicketRequest,
      TicketNonTechnical,
      NoTickets,

      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD' // Uses 'iso' if missing
      },
      dateRange: {
        start: '',
        end: ''
      },
      currentPage: 1,
      ticketType: {
        name: 'All',
        sub: 'All type',
        to: 'all',
        id: 0
      },
      reqLoading: false,
      exportLoading: false,
      instrument: null,
      status: null,
      priority: null,
      search: '',
      timeout: null,
      tt: [1, 2]
    }
  },
  computed: {
    ...mapState({
      tickets: state => state.ticket.tickets,
      statuses: state => state.ticket.statuses,
      priorities: state => state.ticket.priorities
    }),
    ...mapGetters('ticket', ['listInstrument', 'ticketTypes']),
    filter: {
      get() {
        return this.search
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.search = val
        }, 300)
      }
    }
  },
  watch: {
    '$route.query': {
      deep: true,
      handler() {
        this.getData()
      }
    },
    search: function (val) {
      this.setFilter('q', val)
    }
  },
  mounted() {
    this.restoreFilter()
    this.GET_INSTRUMENTS()
  },
  methods: {
    ...mapActions('ticket', ['GET_TICKETS', 'GET_INSTRUMENTS', 'TICKET_DETAIL', 'DOWNLOAD_EXCEL']),
    filterSelect(node, instanceId) {
      this.setFilter(instanceId, node.id)
    },
    filterClear(key, val) {
      this[key] = null
      if (key == 'instrument') {
        key = 'instrument_id'
      }
      this.setFilter(key, val)
    },
    setMaster(key, val) {
      this.instrument = null
      this.status = null
      this.priority = null
      this.ticketType = this.ticketTypes.menus.find(obj => {
        return obj.id == val
      })

      let queries = {}
      queries.t = new Date().getTime()
      queries.offset = 0
      queries.limit = 9
      queries[key] = val

      this.$router.replace({ query: queries })
    },
    setFilter(ky, val) {
      this.ticketType = this.ticketTypes.menus.find(obj => {
        return obj.id == val
      })

      let queries = JSON.parse(JSON.stringify(this.$route.query))

      this.currentPage = 1
      queries.t = new Date().getTime()
      queries.offset = 0
      queries.limit = 9
      queries[ky] = val

      this.$router.replace({ query: queries })
    },

    changePage(val) {
      let offset = (val - 1) * 9
      let queries = JSON.parse(JSON.stringify(this.$route.query))
      queries.t = new Date().getTime()
      queries.offset = offset
      queries.limit = 9

      this.$router.push({ query: queries })
    },
    async getData() {
      this.reqLoading = true
      let params = Object.assign({}, this.$route.query)

      if (params.ticket_type == 0) delete params.ticket_type

      if (params.status == 'closed') params.is_closed = 1

      await this.GET_TICKETS(params)
      this.reqLoading = false
    },
    restoreFilter() {
      let queries = JSON.parse(JSON.stringify(this.$route.query))

      if (queries.status) {
        this.status = queries.status
      }

      if (queries.priority) {
        this.priority = queries.priority
      }

      if (queries.instrument_id) {
        this.instrument = queries.instrument_id
      }

      if (queries.q) {
        this.search = queries.q
      }

      if (queries.ticket_type) {
        this.ticketType = this.ticketTypes.menus.find(obj => {
          return obj.id == queries.ticket_type
        })
      }

      queries.t = new Date().getTime()

      this.$router.replace({ query: queries })
    },
    toDetail(val) {
      let removeSpacing = val.number ? val.number.replaceAll(/\s+/g, '-') : ''
      let removeSlash = removeSpacing.replaceAll('/', '')
      this.$router.push({ name: 'ticket.detail', params: { slug: removeSlash, id: val.id } })
    },
    pageDecrease() {
      if (this.currentPage > 1) {
        this.currentPage -= 1
        this.changePage(this.currentPage)
      }
    },
    pageIncrease() {
      if (this.tickets.length == 9) {
        this.currentPage += 1
        this.changePage(this.currentPage)
      }
    },
    clearRange() {
      this.dateRange = {
        start: '',
        end: ''
      }
    },
    async toExport() {
      this.exportLoading = true

      if (!this.dateRange || (this.dateRange && !this.dateRange.start)) {
        this.$toast.error('Please, select date first.')
        this.exportLoading = false
        return
      }

      let params = {}
      params.date_from = this.dateRange.start
      params.date_to = this.dateRange.end
      params.ticket_type = this.$route.query.ticket_type

      await this.DOWNLOAD_EXCEL(params)

      this.exportLoading = false
    }
  }
}
</script>
